<script>
    export default {
      name: "FooterElement"
    };
</script>
<template>
    <footer>
        <h2>All copyrights reserved ©️</h2>
    </footer>
</template>
<style scoped>
  footer{
   background:linear-gradient(orange,white,green);
   
   height:fit-content;
   width: 100%;
    color:rgb(14, 14, 163); 
   
   font-family: sans-serif;
   text-align:center;
   
  }
</style>