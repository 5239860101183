<template>
<Transition mode="out-in">
    <router-view />
  </Transition>
  <Footer/>
  
</template>

<style>
*{
  margin:0px;
  padding:0px;
  overflow-x:hidden;
  font-family: sans-serif;
}
</style>
<script>
import Footer from "./components/Footer.vue";
export default {
  name:"ApplicationView",
  components: {Footer},
};
</script>
