import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/validation',
    name: 'ValidationView',
    component: () => import('../views/Validation.vue')
  },
  {
    path:'/result',
    name:'Results',
    component:() => import('../views/Result.vue')
  },
 
  {
    path:'/info',
    name:'information',
    component:() => import('../views/information.vue')
  },
  {
    path:'/candidates',
    name:'CandidateView',
    component:() => import('../views/Candidates.vue')
  },
  {
    path:'/AfterVote',
    name:'AfterVote',
    component:() => import('../views/AfterVote.vue')
  },
  {
    path:'/popup',
    name:'Popup',
    component:() => import('../views/popup.vue')
  }
]



const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router